<template>
    <div class="navigation-inner">
        <div v-if="isEditMode" class="back-button" @click="onBackToIntro">
            <i class="el-icon-arrow-left"></i>
            返回案例列表
        </div>
        <div v-else class="back-button" @click="onBackToEdit">
            <i class="el-icon-arrow-left"></i>
            返回繼續編輯
        </div>
        <div v-if="isEditMode" class="button-group">
            <button-wrapper
                type="navigation"
                color="normal"
                :pressed="aeConfirm"
                @click="onConfirm"
            >
                {{ aeConfirm ? "已確認醫師填寫" : "已確認完畢" }}
            </button-wrapper>
            <button-wrapper type="navigation" color="normal" @click="onStore">
                儲存
            </button-wrapper>
            <button-wrapper
                type="navigation"
                color="normal"
                @click="onPreview"
            >
                預覽
            </button-wrapper>
        </div>
        <div v-else class="button-group"></div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import FormNavigation from '@/mixins/FormNavigation';

export default {
    mixins: [FormNavigation],
    computed: {
        ...mapState({
            isFormStored: (state) => state.form.isStored,
            mode: (state) => state.form.mode,
            aeConfirm: (state) => state.form.aeConfirm,
        }),
        isFormPage() {
            return this.$route.path.match(/form/);
        },
        isEditMode() {
            return this.mode === 'EDIT';
        },
    },
    methods: {
        ...mapMutations({
            setFormStoredState: 'form/setFormStoredState',
            setMode: 'form/setMode',
            setAEConfirm: 'form/setAEConfirm',

            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        onBackToIntro() {
            if (this.isFormStored) this.$router.push({ name: 'Sales_Case_Index' });
            else {
                this.updateConfirmationText({
                    cancel: '取消',
                    confirm: '確定',
                    title: '案例尚未儲存確定要返回嗎？',
                });
                this.updateConfirmationState(true);
                this.updateConfirmationFunction(() => {
                    this.$router.push({ name: 'Sales_Case_Index' });
                });
            }
        },
        async onStore() {
            await this.onCheckAndCallApi('saveDraft');
            this.$router.push({ name: 'Sales_Case_Index' });
            this.updateConfirmationText({
                confirm: '我暸解了',
                title: '已新增一篇案例若要送出審核請至「我的目錄」中處理',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(() => {});
        },
        async onConfirm() {
            try {
                const ID = this.$route.params.id;
                const PAYLOAD = {
                    category: 'Case',
                    function: 'aeConfirm',
                    data: ID,
                };
                await this.apiClient(PAYLOAD);
                this.setAEConfirm(true);
            } catch (e) {
                this.$message({
                    message: e.response.data.msg,
                    type: 'error',
                });
            }
        },
        onPreview() {
            this.setMode('VIEW');
        },
        onBackToEdit() {
            this.setMode('EDIT');
        },
    },
};
</script>

<style lang="scss" scoped>
.button-group {
    display: flex;
    > * {
        margin-right: 20px;
    }
    :last-child {
        margin-right: 0;
    }
}
</style>
