<template>
    <div class="navigation-inner">
        <div class="logo">
            <img src="/roche-logo.svg" alt="" width="100%" />
            <div class="current-title">{{ currentTitle }}</div>
        </div>
        <Hamburger :open="isHamburgerOpen" @toggle="toggleHamburger" />
        <Menu :trigger="isHamburgerOpen" :on-close="closeHamburger" />
    </div>
</template>
<script>
import Menu from '@/components/Navigation/Manager/Menu.vue';
import Hamburger from '@/components/Navigation/Hamburger.vue';

export default {
    components: { Menu, Hamburger },
    data() {
        return {
            isHamburgerOpen: false,
        };
    },
    computed: {
        currentTitle() {
            switch (this.$route.name) {
                case 'Manager_Case_List':
                    return '案例列表';
                case 'Manager_Sales_List':
                    return 'PJP管理';
                case 'Manager_Key_Message':
                    return 'Key Message管理';
                case 'Manager_Headline':
                    return '大聲公管理';
                case 'Disclaimer_Manage':
                    return '免責聲明管理';
                default:
                    return '';
            }
        },
    },
    methods: {
        toggleHamburger() {
            this.isHamburgerOpen = !this.isHamburgerOpen;
        },
        closeHamburger() {
            this.isHamburgerOpen = false;
        },
    },
};
</script>
