<template>
    <div class="navigation-inner">
        <div class="logo">
            <router-link
                v-if="leaderBoardPlugin"
                :to="{ name: 'Sales_Case_Leader_Board' }"
            >
                <img src="/roche-logo.svg" alt="" width="100%" />
            </router-link>
            <img v-else src="/roche-logo.svg" alt="" width="100%" />
            <div class="current-title">{{ currentTitle }}</div>
        </div>
        <div class="button-set">
            <button-wrapper
                type="navigation"
                color="secondary"
                :pressed="isCurrentPath('Sales_Case_List')"
                @click="$router.push({ name: 'Sales_Case_List' })"
            >
                案例列表
            </button-wrapper>
            <button-wrapper
                type="navigation"
                color="secondary"
                :pressed="isCurrentPath('Sales_Case_Index')"
                @click="$router.push({ name: 'Sales_Case_Index' })"
            >
                我的目錄
            </button-wrapper>
            <button-wrapper
                type="navigation"
                color="primary"
                :plus="true"
                @click="onCreateCase"
            >
                新增案例
            </button-wrapper>
            <Hamburger :open="isHamburgerOpen" @toggle="toggleHamburger" />
        </div>
        <Menu :trigger="isHamburgerOpen" :on-close="closeHamburger" />
    </div>
</template>

<script>
import Menu from '@/components/Navigation/Sales/Menu.vue';
import Hamburger from '@/components/Navigation/Hamburger.vue';
import { mapState, mapMutations } from 'vuex';

export default {
    components: { Menu, Hamburger },
    data() {
        return {
            isHamburgerOpen: false,
        };
    },
    computed: {
        ...mapState({
            leaderBoardPlugin: (state) => state.config.plugins
                && state.config.plugins.sales.includes(
                    'Sales_Case_Leader_Board',
                ),
        }),
        currentTitle() {
            switch (this.$route.name) {
                case 'Sales_Case_Index':
                    return '我的目錄';
                case 'Sales_Case_List':
                    return '案例列表';
                case 'Sales_Case_Leader_Board':
                    return '即時個案榜';
                default:
                    return '';
            }
        },
    },
    methods: {
        ...mapMutations({
            updateGenerateLinkAndPasswordPopUpState:
                'popup/updateGenerateLinkAndPasswordPopUpState',
            setMode: 'form/setMode',
        }),
        onCreateCase() {
            this.updateGenerateLinkAndPasswordPopUpState(true);
        },
        isCurrentPath(name) {
            return this.$route.name === name;
        },
        toggleHamburger() {
            this.isHamburgerOpen = !this.isHamburgerOpen;
        },
        closeHamburger() {
            this.isHamburgerOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.button-set {
    align-items: center;
}
</style>
