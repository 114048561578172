<template>
    <div class="navigation-inner">
        <div
            v-if="!isEditable || isEditMode"
            class="back-button"
            @click="onBackToIntro"
        >
            <i class="el-icon-arrow-left"></i>
            返回案例列表
        </div>
        <div v-else class="back-button" @click="onBackToEdit">
            <i class="el-icon-arrow-left"></i>
            返回繼續編輯
        </div>
        <div v-if="isEditMode" class="button-group">
            <button-wrapper
                type="navigation"
                color="normal"
                :pressed="aeConfirm"
                @click="onConfirm"
            >
                {{ aeConfirm ? "已確認醫師填寫" : "已確認完畢" }}
            </button-wrapper>
            <button-wrapper type="navigation" color="normal" @click="onStore">
                儲存
            </button-wrapper>
            <button-wrapper type="navigation" color="normal" @click="onPreview">
                預覽
            </button-wrapper>
        </div>
        <div v-else class="button-group">
            <button-wrapper
                v-if="false"
                type="navigation"
                color="normal"
                @click="onSaveHtml"
            >
                匯出html
            </button-wrapper>
            <button-wrapper
                v-if="isNotMobile"
                type="navigation"
                color="normal"
                @click="onPrintAndDownloadPdf"
            >
                列印/下載PDF
            </button-wrapper>
            <!-- <button-wrapper type="navigation" color="normal" @click="onPrintAndDownloadPdf">
                列印
            </button-wrapper> -->
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import FormNavigation from '@/mixins/FormNavigation';

export default {
    mixins: [FormNavigation],
    computed: {
        ...mapState({
            isFormStored: (state) => state.form.isStored,
            mode: (state) => state.form.mode,
            state: (state) => state.manager.state,
            aeConfirm: (state) => state.form.aeConfirm,
        }),
        isFormPage() {
            return this.$route.path.match(/form/);
        },
        isEditMode() {
            return this.mode === 'EDIT';
        },
        isEditable() {
            switch (this.state) {
                case 'display':
                case 'review':
                case 'approve':
                    return false;
                case 'draft':
                case 'doctor-save':
                case 'reject':
                case 'doctor-send':
                default:
                    return true;
            }
        },
        isNotMobile() {
            return !window.navigator.userAgent.match('Mobile');
        },
    },
    methods: {
        ...mapMutations({
            setFormStoredState: 'form/setFormStoredState',
            setMode: 'form/setMode',
            setAEConfirm: 'form/setAEConfirm',

            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        onBackToIntro() {
            if (this.isFormStored) this.$router.push({ name: 'Manager_Case_List' });
            else {
                this.updateConfirmationText({
                    cancel: '取消',
                    confirm: '確定',
                    title: '案例尚未儲存確定要返回嗎？',
                });
                this.updateConfirmationState(true);
                this.updateConfirmationFunction(() => {
                    this.$router.push({ name: 'Manager_Case_List' });
                });
            }
        },
        async onStore() {
            await this.onCheckAndCallApi('saveDraft');
            this.$router.push({ name: 'Manager_Case_List' });
        },
        async onConfirm() {
            try {
                const ID = this.$route.params.id;
                const PAYLOAD = {
                    category: 'Case',
                    function: 'aeConfirm',
                    data: ID,
                };
                await this.apiClient(PAYLOAD);
                this.setAEConfirm(true);
            } catch (e) {
                this.$message({
                    message: e.response.data.msg,
                    type: 'error',
                });
            }
        },
        onPreview() {
            this.setMode('VIEW');
        },
        onBackToEdit() {
            this.setMode('EDIT');
        },
        async onPrintAndDownloadPdf() {
            window.addEventListener('beforeprint', this.onBeforePrint);
            window.addEventListener('afterprint', this.onAfterPrint);

            window.print();
        },
        onBeforePrint() {
            const PRINT = document.getElementById('print');
            const PRINT_IMG = document.getElementById('print-bg');
            const APP = document.getElementById('app');
            const PRINT_OBJ = document
                .getElementById('main-form')
                .cloneNode(true);
            const DISEASE = this.$route.params.disease;

            APP.style.opacity = 0;
            APP.style.height = 0;
            PRINT_OBJ.id = 'main-form-cloned';
            PRINT_IMG.src = `/bg/${DISEASE}.jpg`;
            PRINT.appendChild(PRINT_OBJ);
        },
        onAfterPrint() {
            const PRINT = document.getElementById('print');
            const APP = document.getElementById('app');
            const PRINT_IMG = document.getElementById('print-bg');
            const CLONE_PRINT_OBJ = document.getElementById('main-form-cloned');

            APP.style.opacity = 1;
            APP.style.height = 'auto';
            PRINT_IMG.src = undefined;
            PRINT.removeChild(CLONE_PRINT_OBJ);

            window.removeEventListener('beforeprint', this.onBeforePrint);
            window.removeEventListener('afterprint', this.onAfterPrint);
        },
        onSaveHtml() {
            const PRINT_OBJ = document.getElementById('main-form').innerHTML;
            const FAKE_A = document.createElement('a');
            FAKE_A.setAttribute(
                'href',
                `data:text/plain;charset=utf-8,${encodeURIComponent(PRINT_OBJ)}`,
            );
            FAKE_A.setAttribute('download', 'case.html');

            FAKE_A.style.display = 'none';
            document.body.appendChild(FAKE_A);

            FAKE_A.click();

            document.body.removeChild(FAKE_A);
        },
    },
};
</script>

<style lang="scss" scoped>
.button-group {
    display: flex;
    > * {
        margin-right: 20px;
    }
    :last-child {
        margin-right: 0;
    }
}
</style>
