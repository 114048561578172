export default [
    {
        label: 'Key Message管理',
        name: 'Manager_Key_Message',
    },
    {
        label: '大聲公管理',
        name: 'Manager_Headline',
    },
];
