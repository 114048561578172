import _ from 'lodash';
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            target: (state) => state.form.target,
            configs: (state) => state.config.question,
        }),
    },
    methods: {
        async onCheckAndCallApi(functionName) {
            this.configs.forEach((page) => {
                page.forEach((item) => {
                    if (
                        item.depend
                        && !this.target[item.depend.fieldName].match(item.depend.rule)
                    ) {
                        delete this.target[item.fieldName];
                    }
                });
            });
            const data = { ...this.target };
            _.forEach(this.target, (fieldItem, key) => {
                if (Array.isArray(fieldItem) && typeof fieldItem[0] === 'object') {
                    let isCompletelyEmpty = true;
                    fieldItem.forEach((fieldChild) => {
                        _.forEach(fieldChild, (item) => {
                            if (item) isCompletelyEmpty = false;
                        });
                    });
                    if (isCompletelyEmpty) data[key] = undefined;
                }
            });
            const DATA = {
                id: this.$route.params.id,
                content: {
                    date: this.target.date || null,
                    title: this.target.title || '',
                    doctorName: this.target.doctorName || '',
                    doctorUCI: this.target.doctorUCI,
                    hospital: this.target.hospital || '',
                    doctorAvatar: this.target.doctorAvatar,
                    data,
                },
            };
            const PAYLOAD = {
                category: 'Case',
                function: functionName,
                data: DATA,
            };
            await this.apiClient(PAYLOAD);
        },
    },
};
