<template>
    <div class="navigation-inner">
        <div
            v-if="isFormSend || (!isFormSend && isEditMode)"
            class="back-button"
            @click="onBackToIntro"
        >
            <i class="el-icon-arrow-left"></i>
            返回
        </div>
        <div v-else class="back-button" @click="onBackToEdit">
            <i class="el-icon-arrow-left"></i>
            返回繼續編輯
        </div>
        <div class="button-group">
            <button-wrapper
                v-if="isEditMode"
                type="navigation"
                color="normal"
                @click="onStore"
            >
                儲存
            </button-wrapper>
            <button-wrapper
                v-if="isEditMode"
                type="navigation"
                color="normal"
                @click="onPreview"
            >
                預覽
            </button-wrapper>
            <button-wrapper
                v-if="!isFormSend"
                type="navigation"
                color="primary"
                @click="onSubmit"
            >
                送出
            </button-wrapper>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import FormNavigation from '@/mixins/FormNavigation';

export default {
    mixins: [FormNavigation],
    computed: {
        ...mapState({
            isFormStored: (state) => state.form.isStored,
            mode: (state) => state.doctor.mode,
            state: (state) => state.doctor.state,
            validateFunction: (state) => state.form.validateFunction,
        }),
        isFormPage() {
            return this.$route.path.match(/form/);
        },
        isEditMode() {
            return this.mode === 'EDIT';
        },
        isFormSend() {
            return !(
                this.state === 'doctor-save'
                || this.state === 'draft'
                || this.state === 'reject'
            );
        },
    },
    destroyed() {
        this.setFormStoredState(true);
    },
    methods: {
        ...mapMutations({
            setFormStoredState: 'form/setFormStoredState',
            setMode: 'doctor/setMode',

            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
            setState: 'doctor/setState',
        }),
        onBackToIntro() {
            if (this.isFormStored) this.$router.push({ name: 'Doctor_Intro' });
            else {
                this.updateConfirmationText({
                    cancel: '取消',
                    confirm: '確定',
                    title: '案例尚未儲存確定要返回嗎？',
                });
                this.updateConfirmationState(true);
                this.updateConfirmationFunction(() => {
                    this.$router.push({ name: 'Doctor_Intro' });
                });
            }
        },
        onSubmit() {
            this.validateFunction((valid, i) => {
                if (valid) {
                    this.updateConfirmationText({
                        cancel: '取消',
                        confirm: '確定',
                        title: '請確定資訊是否正確無誤？',
                        subtitle: '送出後將無法修改',
                    });
                    this.validateSubmit();
                    return true;
                }
                const FIRST_UNCOMPLETION = Object.keys(i)[0];
                const SCROLL_TO_ELEMENT = document.getElementById(FIRST_UNCOMPLETION);
                if (SCROLL_TO_ELEMENT) {
                    SCROLL_TO_ELEMENT.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
                this.$message({
                    message: '尚未欄位填寫',
                    type: 'error',
                });
                return false;
            });
        },
        async validateSubmit() {
            const ID = this.$route.params.id;
            const DATA = this.target;
            let VALIDATE = true;
            const UnCompletion = {};
            if (!DATA) {
                VALIDATE = false;
            } else {
                this.configs.forEach((page) => {
                    page.forEach((item) => {
                        if (item.rules && item.rules.required) {
                            if (
                                item.depend
                                && DATA[item.depend.fieldName].match(
                                    item.depend.rule,
                                )
                            ) {
                                if (
                                    !DATA[item.fieldName]
                                    || DATA[item.fieldName].length === 0
                                ) {
                                    UnCompletion.label = item.label;
                                    UnCompletion.id = item.id;
                                    VALIDATE = false;
                                }
                            } else if (
                                !DATA[item.fieldName]
                                || DATA[item.fieldName].length === 0
                            ) {
                                UnCompletion.label = item.label;
                                UnCompletion.id = item.id;
                                VALIDATE = false;
                            }
                        }
                    });
                });
            }
            if (!VALIDATE) {
                const SCROLL_TO_ELEMENT = document.getElementById(
                    UnCompletion.id,
                );
                if (SCROLL_TO_ELEMENT) {
                    SCROLL_TO_ELEMENT.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
                this.$message({
                    message: UnCompletion.label
                        ? `${UnCompletion.label}尚未完成`
                        : '尚未儲存任何資料',
                    type: 'error',
                });
            } else {
                this.updateConfirmationState(true);
                this.updateConfirmationFunction(async () => {
                    const SUBMIT = {
                        id: ID,
                        content: {
                            date: this.target.date || null,
                            title: this.target.title || '',
                            doctorName: this.target.doctorName || '',
                            doctorUCI: this.target.doctorUCI,
                            hospital: this.target.hospital || '',
                            doctorAvatar: this.target.doctorAvatar,
                            data: this.target,
                        },
                    };
                    const PAYLOAD = {
                        category: 'Case',
                        function: 'doctorSend',
                        successMsg: '案例已送出',
                        data: SUBMIT,
                    };
                    await this.apiClient(PAYLOAD);
                    this.setState(this.$route.params.id);
                    this.$router.push({ name: 'Doctor_Intro' });
                });
            }
        },
        async onStore() {
            await this.onCheckAndCallApi('doctorSave');
            this.$router.push({ name: 'Doctor_Intro' });
        },
        onPreview() {
            this.setMode('VIEW');
        },
        onBackToEdit() {
            this.setMode('EDIT');
        },
    },
};
</script>

<style lang="scss" scoped>
.button-group {
    display: flex;
    > * {
        margin-right: 20px;
    }
    :last-child {
        margin-right: 0;
    }
}
</style>
