<template>
    <pop-up background-color="rgba(0, 0, 0, 0.7)" width="900px" cardColor="#ffffffea">
        <div class="main">
            <div class="title">羅氏揭露訊息</div>
            <div class="content-text">
                © 2024 台灣羅氏大藥廠版權所有<br />
                <a href="https://www.iroche.com.tw/privacy-policy.html" target="_blank">隱私聲明</a>
                <a
                    style="margin-left: 10px;"
                    href="https://www.iroche.com.tw/legal-statement.html"
                    target="_blank"
                    >法律聲明</a
                ><br />
                本網頁目的在供台灣醫護人員使用。<br />
                本平台原非用於通報不良事件（副作用），但您可透過網站，向台灣羅氏藥品安全部門通報不良事件，
                或前往<a href="https://adr.fda.gov.tw/" target="_blank">全國藥物不良反應系統網站</a
                >進行通報。<br />
                聯絡資訊
                若想查閱羅氏所持有您的個人資料，要求羅氏變更或更正您的個人資料，將您的個人資料自羅氏系統中移除，
                或取得羅氏隱私政策副本或查詢相關問題，請郵件台北市115018南港區經貿一路170號10樓與我們聯絡。
                <br />
                若想了解我們如何收集，使用，共享，保護與處理您的資料，請參考羅氏隱私政策，羅氏有權隨時依適用法規及其它適用隱私法規修訂隱私政策。
                <br />
                羅氏網站及其中所含資訊僅供參考。嚴禁重製，轉發或用於其它目的。若希望重制本網站所含的任何資訊，請向羅氏網站管理員申請許可。
                <br />
                <br />
                Published date 2024.08
                <br />
                <br />
                台北市115018南港區經貿一路170號10樓
                <br />羅氏大藥廠股份有限公司
                <br />(02)2715-3111
            </div>
            <button-wrapper type="navigation" color="primary" @click="onConfirm">
                我瞭解了
            </button-wrapper>
        </div>
    </pop-up>
</template>

<script>
import PopUp from '@/components/Popup/PopUpWrapper.vue';
import { mapActions, mapMutations } from 'vuex';

export default {
    components: { PopUp },
    props: {
        img: String,
        fileName: String
    },

    data() {
        return {
            imgUrl:
                'https://norserium.github.io/vue-advanced-cropper/assets/img/photo.59242653.jpeg',
            imgDataUrl: undefined,
            canvasEl: undefined
        };
    },
    methods: {
        ...mapMutations({
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState'
        }),
        ...mapActions({
            apiClient: 'api/invoke'
        }),
        onConfirm() {
            this.updateRevealInformationPopupState(false);
        }
    }
};
</script>

<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    overflow-y: auto;
    padding-bottom: 20px;
    @media screen and (min-width: 640px) {
        margin-top: 55px;
        margin-bottom: 40px;
    }
    .title {
        color: #000000;
        font-size: 20px;
        text-align: center;
        margin-bottom: 25px;
    }
    .content-text {
        white-space: normal;
        font-size: 16px;
        margin-bottom: 50px;
        @media screen and (min-width: 640px) {
            margin-bottom: 0px;
            padding: 60px;
        }
    }
}
</style>
