<template>
    <div class="navigation-inner">
        <div class="back-button" @click="onBackToList">
            <i class="el-icon-arrow-left"></i>
            返回案例列表
        </div>
        <button-wrapper
            type="navigation"
            color="primary"
            :plus="true"
            @click="onCreateCase"
        >
            新增案例
        </button-wrapper>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    methods: {
        ...mapMutations({
            updateGenerateLinkAndPasswordPopUpState:
                'popup/updateGenerateLinkAndPasswordPopUpState',
        }),
        onBackToList() {
            this.$router.push({ name: 'Sales_Case_List' });
        },
        onCreateCase() {
            this.updateGenerateLinkAndPasswordPopUpState(true);
        },
    },
};
</script>
