<template>
    <transition name="fade" ease="in-out">
        <div v-if="trigger" class="menu">
            <div class="mask"></div>
            <div class="content" :class="{ active: trigger }">
                <div class="card">
                    <Hamburger class="close" :open="true" @toggle="onClose" />
                    <div class="inner">
                        <div
                            v-for="(page, index) in pages"
                            :key="index"
                            :class="{ active: $route.name === page.name }"
                            @click="goTo(page.name)"
                        >
                            {{ page.label }}
                        </div>
                    </div>
                    <div class="logout" @click="logout">登出</div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Hamburger from '@/components/Navigation/Hamburger.vue';
import _ from 'lodash';
import { mapState } from 'vuex';

import Plugins from '@/config/plugins';

export default {
    components: { Hamburger },
    props: {
        trigger: {
            type: Boolean,
        },
        onClose: {
            type: Function,
        },
    },
    watch: {
        $route() {
            this.onClose();
        },
    },
    created() {
        if (!this.config) return;
        this.config.forEach((disableItem) => {
            const FOUND = _.findIndex(Plugins, (o) => o.name === disableItem);
            this.pages.push(Plugins[FOUND]);
        });
    },
    computed: {
        ...mapState({
            config: (state) => state.config.plugins && state.config.plugins.manager,
        }),
    },
    data() {
        return {
            pages: [
                {
                    label: '案例列表',
                    name: 'Manager_Case_List',
                },
                {
                    label: 'PJP管理',
                    name: 'Manager_Sales_List',
                },
                {
                    label: '免責聲明管理',
                    name: 'Disclaimer_Manage',
                },
            ],
        };
    },
    methods: {
        goTo(name) {
            this.$router.push({ name });
        },
        logout() {
            window.localStorage.clear();
            this.$router.go(0);
        },
    },
};
</script>

<style lang="scss" scoped>
.move-enter,
.move-leave-to {
    right: 0;
}
.move-enter-active,
.move-leave-active {
    transition: opacity 0.2s ease-out;
}
.menu {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .close {
        cursor: pointer;
        width: fit-content;
        align-self: flex-end;
        margin-bottom: 10px;
        width: 50px;
        height: 30px;
    }

    .content {
        height: 100%;
        position: absolute;
        top: 50%;
        right: -100%;
        transform: translate(0%, -50%);
        &.active {
            right: 0;
        }
    }
    .card {
        background: #fff;
        width: 300px;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        display: flex;
        flex-direction: column;
    }
    .inner {
        height: 100%;
        > * {
            cursor: pointer;
            width: fit-content;
            margin-bottom: 60px;
            &.active {
                text-decoration: underline;
            }
        }
    }
    .logout {
        cursor: pointer;
        font-size: 16px;
    }
}
</style>
