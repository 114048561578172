<template>
    <div class="hamburger" :class="{ open }" @click="$emit('toggle')">
        <div class="hamburger--layer--1"></div>
        <div class="hamburger--layer--2"></div>
        <div class="hamburger--layer--3"></div>
    </div>
</template>

<script>
export default {
    props: {
        open: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.hamburger {
    cursor: pointer;
    position: relative;
    width: 50px;
    height: 30px;
    div {
        transition: 0.3s;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 25px;
        height: 2px;
        background-color: #737d82;
    }
    &--layer {
        &--1 {
            top: 30%;
        }
        &--2 {
            top: 50%;
        }
        &--3 {
            top: 70%;
        }
    }
    &.open {
        .hamburger--layer {
            &--1 {
                top: 50%;
                left: 25%;
                transform: rotate(135deg);
            }
            &--3 {
                transform: rotate(-135deg);
                top: 50%;
                left: 25%;
                width: 25px;
            }
            &--2 {
                left: 100%;
                opacity: 0;
            }
        }
    }
}
</style>
