<template>
    <div class="navigation-bar" :class="{ 'dark-bg': isDarkBg }">
        <div class="wrapper">
            <component :is="currentNavbar"></component>
        </div>
    </div>
</template>

<script>
import DoctorLineCase from '@/components/Navigation/Doctor/LineCase.vue';
import DoctorDefault from '@/components/Navigation/Doctor/Default.vue';
import DoctorForm from '@/components/Navigation/Doctor/Form.vue';
import ManagerDefault from '@/components/Navigation/Manager/Default.vue';
import ManagerForm from '@/components/Navigation/Manager/Form.vue';
import SalesCase from '@/components/Navigation/Sales/Case.vue';
import SalesDefault from '@/components/Navigation/Sales/Default.vue';
import SalesForm from '@/components/Navigation/Sales/Form.vue';

export default {
    components: {
        DoctorLineCase,
        DoctorDefault,
        DoctorForm,
        ManagerDefault,
        ManagerForm,
        SalesDefault,
        SalesForm,
        SalesCase
    },
    computed: {
        currentNavbar() {
            switch (this.$route.name) {
                case 'Doctor_Login':
                case 'Doctor_Intro':
                case 'Line_Doctor_Case_List':
                    return 'doctor-default';
                case 'Doctor_Form':
                    return 'doctor-form';
                case 'Line_Doctor_Case':
                    return 'doctor-line-case';
                case 'Manager_Case_List':
                case 'Manager_Sales_List':
                case 'Manager_Key_Message':
                case 'Manager_Headline':
                    return 'manager-default';
                case 'Manager_Form':
                    return 'manager-form';
                case 'Disclaimer_Manage':
                    return 'manager-default';
                case 'Sales_Case_Index':
                case 'Sales_Case_List':
                case 'Sales_Case_Leader_Board':
                    return 'sales-default';
                case 'Sales_Form':
                    return 'sales-form';
                case 'Sales_Case':
                    return 'sales-case';
                default:
                    return '';
            }
        },
        isDarkBg() {
            switch (this.$route.name) {
                case 'Doctor_Login':
                case 'Doctor_Intro':
                    return false;
                default:
                    return true;
            }
        },
        isOnIpad() {
            return !this.$route.path.match(/manager/);
        }
    }
};
</script>

<style lang="scss" scoped>
.navigation-bar {
    top: 0;
}
</style>
