<template>
    <div class="navigation-inner">
        <div class="back-button" @click="onBackToList">
            <i class="el-icon-arrow-left"></i>
            返回案例列表
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    methods: {
        ...mapMutations({
            updateGenerateLinkAndPasswordPopUpState: 'popup/updateGenerateLinkAndPasswordPopUpState'
        }),
        onBackToList() {
            this.$router.push({ name: 'Line_Doctor_Case_List' });
        },
        onCreateCase() {
            this.updateGenerateLinkAndPasswordPopUpState(true);
        }
    }
};
</script>
